"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventsContainer = void 0;
var HandlersCollection_1 = require("./Tools/HandlersCollection");
var EventsContainer = /** @class */ (function () {
    function EventsContainer(element) {
        this.element = element;
        this.events = {}; // string because TS does not allow GlobalEventHandlersEventMap as type
        this.listeners = {};
    }
    EventsContainer.prototype.Add = function (event, handler) {
        var _this = this;
        if (!handler) {
            return;
        }
        if (Array.isArray(event)) {
            event.forEach(function (ev) { return _this.AddOne(ev, handler); });
        }
        else
            this.AddOne(event, handler);
    };
    EventsContainer.prototype.AddOne = function (event, handler) {
        var _this = this;
        if (!this.events[event]) {
            this.events[event] = new HandlersCollection_1.HandlersCollection();
        }
        this.events[event].Add(handler);
        if (this.listeners[event]) // code below need to be executed only once 
         {
            return;
        }
        this.listeners[event] = function (e) {
            // e.preventDefault();
            // console.log(e);
            // const copy = {};
            // for (let prop in e) {
            //   if (typeof event[prop] !== 'function') {
            //     copy[prop] = event[prop];
            //   }
            // }
            // // const copy = Object.assign({}, e)
            // console.log(copy);
            _this.Call(event, e);
            // console.log(event ,e.code);
            // return false;
        };
        this.element.addEventListener(event, function (e) { return _this.listeners[event](e); });
    };
    EventsContainer.prototype.Call = function (event) {
        var _a;
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        (_a = this.events[event]) === null || _a === void 0 ? void 0 : _a.Call.apply(_a, args);
    };
    EventsContainer.prototype.CallAsync = function (event) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, ((_a = this.events[event]) === null || _a === void 0 ? void 0 : _a.CallAsync.apply(_a, args))];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    EventsContainer.prototype.Off = function (event) {
        var _a;
        if (!this.events.Keys().includes(event)) {
            return;
        }
        (_a = this.events[event]) === null || _a === void 0 ? void 0 : _a.Reset();
        this.element.addEventListener(event, this.listeners[event]);
    };
    Object.defineProperty(EventsContainer.prototype, "Info", {
        get: function () {
            var _this = this;
            var t = "";
            this.listeners.Keys().forEach(function (k) {
                t += "- " + k + ": " + _this.events[k].Count + " handlers\n";
            });
            return t;
        },
        enumerable: false,
        configurable: true
    });
    return EventsContainer;
}());
exports.EventsContainer = EventsContainer;
