"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CopyIcon = void 0;
var A_1 = require("./A");
/*
    NIE WIEM O CO KAMAN ALE KIEDY TA KLASA DZIEDZICZY PO Link TO DOSTAJE:
    Cannot access 'Link' before initialization
    CO CIEKAWE KIEDY ZADEKLARUJE SIE TE KLASE ZARAZ POD Link (W PLIKU Link.ts) TO WSZYSTKO DZIAŁA!
    LUB KIEDY ZROBI SIĘ DOKŁADNĄ KOPIE Link I TYLKO INACZEJ NAZWIE TO TEŻ DZIAŁA!
    ŻADNE CZYSZCZENIE, ZMIENIANIE IMPORTÓW, REINSTALACJA NARZĘDZI NIE POMAGA. NIE WIEM O CO CHODZI :(
*/
var CopyIcon = /** @class */ (function (_super) {
    __extends(CopyIcon, _super); // 'extends A' because when trying to 'extend Link' it throws 'Cannot access 'Link' before initialization', which does not make sense!!!
    function CopyIcon(value, icon) {
        if (icon === void 0) { icon = "📋"; }
        var _this = _super.call(this, icon) || this;
        _this.MarginLeft(8)
            .OnClick(function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, this.CopyToClipboard(value)];
                case 1: return [2 /*return*/, _a.sent()];
            }
        }); }); });
        return _this;
    }
    CopyIcon.prototype.CopyToClipboard = function (textToCopy) {
        // navigator clipboard api needs a secure context (https)
        if (navigator.clipboard && window.isSecureContext) {
            return new Promise(function (res, rej) {
                // navigator clipboard api method'
                navigator.clipboard.writeText(textToCopy);
                res("Success");
            });
        }
        else {
            // text area method
            var textArea_1 = document.createElement("textarea");
            textArea_1.value = textToCopy;
            // make the textarea out of viewport
            textArea_1.style.position = "fixed";
            textArea_1.style.left = "-999999px";
            textArea_1.style.top = "-999999px";
            document.body.appendChild(textArea_1);
            textArea_1.focus();
            textArea_1.select();
            return new Promise(function (res, rej) {
                // here the magic happens
                document.execCommand('copy') ? res("Copy to clipboard succeeded") : rej("Copy to clipboard failed");
                textArea_1.remove();
            });
        }
    };
    return CopyIcon;
}(A_1.A // 'extends A' because when trying to 'extend Link' it throws 'Cannot access 'Link' before initialization', which does not make sense!!!
));
exports.CopyIcon = CopyIcon;
