"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Table = void 0;
var __1 = require("../..");
var Table = /** @class */ (function (_super) {
    __extends(Table, _super);
    function Table(headers, body) {
        var _this = _super.call(this, "table") || this;
        _this.Class(Table.DefaultCssClasses);
        if (headers)
            _this.AddHeaders.apply(_this, headers);
        if (body)
            body.forEach(function (row) {
                return _this.AddRow.apply(_this, row);
            });
        return _this;
    }
    Table.prototype.AddHeaders = function () {
        var headers = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            headers[_i] = arguments[_i];
        }
        this.Append(new __1.TableHeader(headers));
        return this;
    };
    Table.prototype.AddRow = function () {
        var _a;
        var cells = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            cells[_i] = arguments[_i];
        }
        this.Append((_a = new __1.TableRow())
            .AddCells.apply(_a, cells));
        return this;
    };
    Table.Name = "Table";
    Table.DefaultCssClasses = "";
    return Table;
}(__1.ComponentBase));
exports.Table = Table;
